import React, {useEffect, useState} from "react";
import {
  createStyles,
  Navbar,
  UnstyledButton,
  rem,
  Accordion,
  Image,
  Text,
  Flex,
} from "@mantine/core";
import {IconCircle} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import {
  Logo,
  accountDarkIcon,
  accountIcon,
  addTwoIcon,
  billIcon,
  dashboardDuelIcon,
  dashboardIcon,
  inventIcon,
  lumberDarkIcon,
  lumberIcon,
  menuDarkIcon,
  menuIcon,
  orderIcon,
  posDarkIcon,
  posIcon,
  priceIcon,
  productIcon,
  reportDarkIcon,
  reportIcon,
  returnIcon,
  settingDarkIcon,
  settingIcon,
  subCustomerIcon,
  subEstiQuoIcon,
  subEstimationIcon,
  subFinacialIcon,
  subInventReportIcon,
  subInvoiceIcon,
  subNotificationIcon,
  subPackingIcon,
  subPromotionIcon,
  subQuotationIcon,
  subSettingIcon,
  subStoreIcon,
  supplierIcon,
  datepickerIcon,
  layoutIcon
} from "../../../helpers/images";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },

  aside: {
    flex: `0 0 ${rem(80)}`,
    backgroundColor: "#00163B",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  main: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingTop: "50%",
    background:
      "transparent linear-gradient(180deg, #F5F7F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
    paddingInline: "5px",
  },

  mainLink: {
    width: rem(44),
    height: rem(44),
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    background:
      "transparent linear- gradient(180deg, #F5F7F9 0 %, #FFFFFF 100 %) 0% 0 % no - repeat padding- box",
  },

  mainLinkActive: {
    "&, &:hover": {
      background:
        "transparent linear-gradient(180deg, #F5F7F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
      color: "#393939",
      width: "5rem",

      borderBottomRightRadius: "0px",
      borderTopRightRadius: "0px",
      height: "4rem",
    },
  },

  title: {
    minWidth: "max-content",
  },

  logo: {
    display: "flex",
    paddingTop: theme.spacing.md,
  },

  link: {
    fontSize: theme.fontSizes.md,
    cursor: "pointer",
    marginBlock: "2%",
  },
  content: {
    paddingBottom: 0,
    fontSize: theme.fontSizes.xs,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  linkActive: {
    "&, &:hover": {
      borderLeftColor: theme.fn.variant({
        variant: "filled",
        color: theme.primaryColor,
      }).background,
      backgroundColor: theme.fn.variant({
        variant: "filled",
        color: theme.primaryColor,
      }).background,
      color: theme.white,
    },
  },
  controlActive: {
    "&[data-active]": {
      backgroundColor: "#EEF6FF",
      borderColor: "#D8E5F5",
      color: "#0094D1",
      borderRadius: "8px",
    },
  },
}));

const mainLinksMockdata = [
  {icon: menuIcon, label: "Menu", darkIcon: menuDarkIcon},
  {icon: reportIcon, label: "Report", darkIcon: reportDarkIcon},
  {icon: accountIcon, label: "Account", darkIcon: accountDarkIcon},
  {icon: posIcon, label: "POS", darkIcon: posDarkIcon},
  {icon: lumberIcon, label: "Lumber Calculator", darkIcon: lumberDarkIcon},
  // {
  //   icon: dashboardIcon,
  //   label: "Catalogue",
  //   darkIcon: dashboardIcon,
  // name: "Catalogue",
  // none: true,
  // path: "/catalogue",
  // },
  // { icon: createSoIcon, label: 'Create SO', darkIcon: createSoDarkIcon },
  {icon: settingIcon, label: "Settings", darkIcon: settingDarkIcon},
];

const subMensSplit = {
  Search: [],
  Menu: [
    {
      name: "Dashboard",
      children: [],
      icons: dashboardDuelIcon,
      none: true,
      path: "/",
    },
    {
      name: "Customer Management",
      icons: supplierIcon,
      children: [
        {name: "Credit", path: "/menu/credit-list"},
        {name: "Estimation", path: "/menu/estimation/list"},
        {name: "Quotation", path: "/menu/quotation/list"},
        {name: "Rebate Amount", path: "/menu/rebate-amount"},
        {name: "Direct Packing Slip/Unpaid", path: "/menu/packing"},
        {name: "Customer", path: "/menu/customer"},
        {name: "Add Customer", path: "/menu/customer-create"},
      ],
    },
    {
      name: "Supplier Management",
      icons: supplierIcon,
      children: [
        {name: "All Purchase Order", path: "/menu/purchase-order/list"},
        {name: "Create Purchase Order", path: "/menu/purchase-order/create"},
        {
          name: "Waiting For Approval",
          path: "/menu/purchase-order/waiting-list",
        },
        {name: "Approved PO", path: "/menu/purchase-order/approve-list"},
        {
          name: "Waiting For Receive",
          path: "/menu/purchase-order/waiting-receive",
        },
        {name: "Completed PO", path: "/menu/purchase-order/compeleted-po"},
        {name: "Cancelled PO", path: "/menu/purchase-order/canceled-list"},
        {name: "Back Order", path: "/menu/purchase-order/back-order"},
        {name: "Suppliers", path: "/menu/supplier/supplier-list"},
        // {
        //   name: "Daily Sheet",
        //   path: "/menu/order/daily-sheet?status=supplier",
        // },
      ],
    },
    {
      name: "Order Management",
      icons: orderIcon,
      children: [
        {name: "All Orders", path: "/menu/order/list"},
        {name: "Orders", path: "/menu/order/order-pending-list"},
        // {name: "In Transit", path: "/menu/order-in-transit-list"},
        {name: "Ready To Go", path: "/menu/order/order-ready-go"},
        {name: "Delivery Status", path: "/menu/order/order-delivery-status"},
        {name: "Complete Orders", path: "/menu/order/complete-order"},
        {name: "Low Stock Orders", path: "/menu/order/low-stock-order"},
        {name: "Back Orders", path: "/menu/order/back-order-list"},
        {name: "Cancel Orders", path: "/menu/order/cancel-order-list"},
        {name: "Unsuccessful Orders", path: "/menu/order/unsuccess-order-list"},
        {
          name: "Delivery Distance",
          path: "/menu/order/delivery-distance/1",
        },

        // { name: "Daily Sheet", path: "/menu/order/daily-sheet?status=order" },
      ],
    },
    {
      name: "Product Management",
      icons: productIcon,
      children: [
        {name: "All Product List", path: "/menu/product/all-list"},
        {name: "Product List", path: "/menu/product/list"},
        {name: "Add Product", path: "/menu/product/create"},
        // {name: "SEO Meta", path: "/menu/seo-meta/list"},
        {name: "Catagories", path: "/menu/categories/list"},
        {name: "Brands", path: "/menu/brand/list"},
        {name: "Import", path: "/menu/import/create"},
        {name: "Remove Product List", path: "/menu/product/removed-list"},
        {name: "Temporary Product List", path: "/menu/temp/temp-list"},
      ],
    },
    {
      name: "Price Management",
      icons: priceIcon,
      children: [
        {name: "Pricing Groups", path: "/menu/pricing-group/list"},
        {
          name: "Price Dashboard",
          path: "/menu/pricing-group/pricedashboard-list",
        },
        {name: "Price Watch", path: "/menu/pricing-group/watch"},
        {name: "Promotion", path: "/menu/promotion"},
      ],
    },
    // {
    //   name: "Temp Management",
    //   icons: tempIcon,
    //   children: [{ name: "Temporary Product List", path: "/menu/temp-list" }],
    // },
    {
      name: "Inventory Management",
      icons: inventIcon,
      children: [
        {name: "Product Stocks", path: "/menu/inventory/product-stock-list"},
        {
          name: "Low Stock Products",
          path: "/menu/inventory/product-lowstock-list",
        },
        {
          name: "Over Stock Products",
          path: "/menu/inventory/product-overstock-list",
        },
        {
          name: "Clearance Products",
          path: "/menu/inventory/product-clearance-list",
        },
        {name: "Purchase Order", path: "/menu/purchase-order/list"},
      ],
    },
    {
      name: "Bill Management",
      icons: billIcon,
      children: [
        {name: "Bill List", path: "/bill-management/bill-list"},
        {name: "Add Bills", path: "/bill-management/create-bill"},
        {
          name: "Completed Bills",
          path: "/bill-management/complete-bill-list",
        },
      ],
    },
    {
      name: "Return Order Management",
      icons: returnIcon,
      children: [
        {name: "Return List", path: "/menu/order/return-order-list"},
        {name: "Return Order", path: "/menu/order/order-to-return-list"},
      ],
    },
  ],
  Report: [
    {
      name: "Invoice Management",
      icons: subInvoiceIcon,
      children: [{name: "Invoice", path: "/report/invoice-list"}],
    },
    {
      name: "Estimation / Quotation",
      icons: subEstiQuoIcon,
      children: [
        {name: "Estimation", path: "/menu/estimation/list?status=report"},
        {name: "Quotation", path: "/menu/quotation/list?status=report"},
      ],
    },
    {
      name: "Packing Slip",
      icons: subPackingIcon,
      children: [{name: "Packing Slip List", path: "/report/packingslip-list"}],
    },
    {
      name: "Financial Reports",
      icons: subFinacialIcon,
      children: [
        {name: "Sales Report", path: "/report/sale-list"},
        {name: "Tax Summary", path: "/report/tax-list"},
        {name: "Transaction Summary", path: "/report/transaction-list"},
        {name: "Receivable Report", path: "/report/receivable-list"},
        {name: "Store Credit Summary", path: "/report/storecredit-list"},
        {name: "Payment Mtd. Total Sum", path: "/report/paymentmtd-list"},
        {name: "Customer Sales Summary", path: "/report/customersale-list"},
        {name: "Product Sales Summary", path: "/report/productsale-list"},
      ],
    },
    {
      name: "Inventory Reports",
      icons: subInventReportIcon,
      children: [
        {name: "Inventory Summary Report", path: "/report/inventory-list"},
        {
          name: "Top Selling Products",
          path: "/report/topsellingproduct-list",
        },
        {name: "Low Stock orders", path: "/report/lowstockorder-list"},
      ],
    },
    {
      name: "Promotion Reports",
      icons: subPromotionIcon,
      children: [
        {name: "Promotion Sales Summary", path: "/report/promotionsale-list"},
      ],
    },
    {
      name: "Customer Reports",
      icons: subCustomerIcon,
      children: [
        {name: "Customer Master Data", path: "/report/masterData-list"},
        {name: "A/R Aging Summary", path: "/report/summary-list"},
        {name: "A/R Aging Details", path: "/report/aging-list"},
      ],
    },
  ],
  Account: [
    {
      name: "Notifications",
      icons: subNotificationIcon,
      children: [{name: "none", path: "/"}],
    },
    {
      name: "Settings & Permission",
      icons: subSettingIcon,
      children: [{name: "none", path: "/"}],
    },
  ],
  POS: [
    {
      name: "Create POS",
      icons: orderIcon,
      children: [],
      none: true,
      path: "/pos",
    },
  ],
  Catalogue: [
    {
      name: "Catalogue",
      icons: addTwoIcon,
      none: true,
      path: "/catalogue",
      children: [],
    },
  ],
  Settings: [
    {
      name: "Estimation",
      icons: subEstimationIcon,
      children: [],
      none: true,
      path: "/settings/estimation",
    },
    {
      name: "Quotation",
      icons: subQuotationIcon,
      children: [],
      none: true,
      path: "/settings/quotation",
    },
    {
      name: "Rebate Date",
      icons: datepickerIcon,
      children: [],
      none: true,
      path: "/settings/rebate-date",
    },
    {
      name: "Store Settings",
      icons: subStoreIcon,
      children: [
        {name: "Store", path: "/settings/store-list"},
        {name: "Add Store", path: "/settings/store-settings"},
        {name: "SEO Meta", path: "/menu/seo-meta/list"},
      ],
    },
    {
      name: "Templates",
      icons: layoutIcon,
      children: [
        {name: "Dry Wall", path: "/settings/template/drywall-template"},
        {name: "Kitchen", path: "/settings/template/kitchen-template"}
      ],
    }
  ],
  "Lumber Calculator": [
    {
      name: "Lumber Calculator",
      icons: addTwoIcon,
      none: true,
      path: "/lumber-calculator",
      children: [],
    },
  ],
  "Create SO": [
    {name: "none", icons: dashboardIcon, children: [{name: "none"}]},
  ],
};

const CustomNavbar = ({closeNavbar, handleClose}) => {
  const {classes, cx} = useStyles();
  const [active, setActive] = useState("Menu");
  const [activeLink, setActiveLink] = useState("Settings");
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [submenus, setSubMenus] = useState([]);
  const navigate = useNavigate();
  const linksMockdata = (submenu) => {
    setActive(submenu);
    setSideBarOpen(submenu === active ? !sideBarOpen : true);
    setSubMenus(subMensSplit[submenu]);
  };

  useEffect(() => {
    if (sideBarOpen) {
      handleClose(sideBarOpen);
    }
  }, [sideBarOpen]);

  useEffect(() => {
    handleClose(false);
    setSideBarOpen(false);
  }, [closeNavbar]);

  return (
    <Navbar
      className="nav-bar"
      height={"100%"}
      width={sideBarOpen === true ? {sm: 325} : {base: 80}}
    >
      <Navbar.Section grow className={classes.wrapper}>
        <div className={classes.aside}>
          <div className={classes.logo}>
            <Image
              className="pointer"
              p={9}
              src={Logo}
              width={25}
              onClick={() => navigate("/")}
            />
          </div>
          {mainLinksMockdata.map((link, index) => (
            <React.Fragment key={index}>
              <UnstyledButton
                ml={8}
                mt={25}
                onClick={() =>
                  // link.name ? navigate(link.path) :
                  linksMockdata(link.label)
                }
                className={cx(classes.mainLink, {
                  [classes.mainLinkActive]: link.label === active,
                })}
              >
                <Flex direction={"column"} align={"center"}>
                  <Image
                    width={"19px"}
                    src={link.label === active ? link.darkIcon : link.icon}
                  />
                  <Text size="xs" align="center">
                    {link.label}
                  </Text>
                </Flex>
              </UnstyledButton>
            </React.Fragment>
          ))}
        </div>
        {sideBarOpen === true && (
          <>
            <div className={classes.main}>
              <>
                <Accordion
                  className={`${classes.title} custom-accordian`}
                  variant="filled"
                >
                  {submenus.map((link, index2) => (
                    <React.Fragment key={index2}>
                      <Accordion.Item
                        className={cx(classes.link, {
                          [classes.linkActive]: activeLink === link.name,
                        })}
                        value={link.name}
                      >
                        <Accordion.Control
                          chevron={link.none}
                          icon={<Image width={"15px"} src={link.icons} />}
                          className={`${classes.controlActive} custom-accordian`}
                          onClick={() =>
                            link.none === true ? navigate(link.path) : null
                          }
                        >
                          {link.name}
                        </Accordion.Control>
                        {link?.children.map((child, index) => (
                          <Accordion.Panel
                            key={index}
                            ml={15}
                            classNames={classes.content}
                            onClick={() => navigate(child.path)}
                          >
                            <Flex align={"center"} gap={10}>
                              <IconCircle size={"10px"} color="#0094D1" />
                              <Text color="font-color.0">{child.name}</Text>
                            </Flex>
                          </Accordion.Panel>
                        ))}
                      </Accordion.Item>
                    </React.Fragment>
                  ))}
                </Accordion>
              </>
            </div>
          </>
        )}
      </Navbar.Section>
    </Navbar>
  );
};

export default CustomNavbar;
