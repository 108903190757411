import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

// rebate-customer-list
export const rebateCustomerList = createAsyncThunk(
  "rebate-customer-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customers/rebate`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const rebateCustomerUpdate = createAsyncThunk(
  "customer-rebate-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(
        `/customers/rebateupdate`, bodyParams
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const rebateAmountReleaseHistoryList = createAsyncThunk(
  "rebate-amount-release-history",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `customers/rebates_history/${bodyParams}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const rebateAmountOrdersHistoryList = createAsyncThunk(
  "rebate-amount-order-history",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `customers/rebate-orders/${bodyParams}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);