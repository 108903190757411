import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";


// Kitchen Template Listing
export const listKitchenTemplate = createAsyncThunk(
  'list-kitchen-template',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/kitchen-templates/list`, { params: bodyParams }, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// Store Create
export const addKitchenTemplate = createAsyncThunk(
  'add-kitchen-template',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/kitchen-templates`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// view single KitchenTemplate
export const singlekitchenTemplateView = createAsyncThunk(
  'single-kitchen-template',
  async (params, thunkAPI) => {
    try {
      const response = await API.get(`/kitchen-templates/slug/${params}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Edit Kitchen Template
export const editKitchenTemplate = createAsyncThunk(
  'edit-kitchen-template',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/kitchen-templates/${bodyParams.id}`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// Delete Kitchen Template
export const deleteKitchenTemplate = createAsyncThunk(
  'delete-kitchen-template',
  async (params, thunkAPI) => {
    try {
      const response = await API.delete(`kitchen-templates/${params}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// Update Active Status Template If Same Slug Available in Other table
export const switchingTemplate = createAsyncThunk(
  'switching-template',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/switching-templates/${bodyParams.id}`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// Dry Wall Template Listing
export const listDryWallTemplate = createAsyncThunk(
  'list-drywall-template',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/drywall-templates/list`, { params: bodyParams }, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })



  // Store Create
export const addDryWallTemplate = createAsyncThunk(
    'add-drywall-template',
    async (bodyParams, thunkAPI) => {
      try {
        const response = await API.post(`/drywall-templates`, bodyParams, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return response
      }
      catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    })
  
  // view single DryWallTemplate
  export const singleDryWallTemplate = createAsyncThunk(
    'single-drywall-template',
    async (params, thunkAPI) => {
      try {
        const response = await API.get(`/drywall-templates/slug/${params}`);
        return response
      }
      catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    })
  // Edit DryWall Template
  export const editDryWallTemplate = createAsyncThunk(
    'edit-drywall-template',
    async (bodyParams, thunkAPI) => {
      try {
        const response = await API.post(`/drywall-templates/${bodyParams.id}`, bodyParams, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return response
      }
      catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    })
  
  // Delete DryWall Template
  export const deleteDryWallTemplate = createAsyncThunk(
    'delete-drywall-template',
    async (params, thunkAPI) => {
      try {
        const response = await API.delete(`drywall-templates/${params}`);
        return response
      }
      catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    })
  





