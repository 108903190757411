import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  customerAllCreditList,
  customerCreditCreate,
  customerCreditDelete,
  customerCreditView,
  customerCreditUpdate,
  customerCreditTermUpdate,
} from "redux/service/menuService/customerService/creditService";

const initialState = {
  customerCreditCreateRes: { data: [], isFetching: false, error: null },
  customerAllCreditListRes: { data: [], isFetching: true, error: null },
  customerCreditDeleteRes: { data: [], isFetching: true, error: null },
  customerCreditViewRes: { data: [], isFetching: true, error: null },
  customerCreditUpdateRes: { data: [], isFetching: false, error: null },
  customerCreditTermUpdateRes: { data: [], isFetching: false, error: null },
};

export const customerCreditSlice = createSlice({
  name: "customerCreditSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: customerCreditCreate, name: "customerCreditCreateRes" },
      { api: customerAllCreditList, name: "customerAllCreditListRes" },
      { api: customerCreditDelete, name: "customerCreditDeleteRes" },
      { api: customerCreditView, name: "customerCreditViewRes" },
      { api: customerCreditUpdate, name: "customerCreditUpdateRes" },
      { api: customerCreditTermUpdate, name: "customerCreditTermUpdateRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { customerDispatch } = customerCreditSlice.actions;
