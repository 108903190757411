import { createSlice } from '@reduxjs/toolkit'
import {
  productCreate, productDelete, productEdit, productList,
  productPricingGroup,
  productRemoveList, productRemoveSingleView,
  productSingleView, productSku, productFilterByPromotion
} from 'redux/service/menuService/productService'


const initialState = {
  productListRes: { data: [], isFetching: false, error: null },
  productSingleViewRes: { data: [], isFetching: false, error: null },
  productSkuRes: { data: [], isFetching: false, error: null },
  productCreateRes: { data: [], isFetching: false, error: null },
  productEditRes: { data: [], isFetching: false, error: null },
  productDeleteRes: { data: [], isFetching: false, error: null },
  productRemoveListRes: { data: [], isFetching: false, error: null },
  productRemoveSingleViewRes: { data: [], isFetching: false, error: null },
  productRemoveUpdateRes: { data: [], isFetching: false, error: null },
  productFilterByPromotionRes: { data: [], isFetching: false, error: null },
  productPricingGroupRes: { data: [], isFetching: false, error: null },
}

export const productSlice = createSlice({
  name: 'productSlice',
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: productList, name: "productListRes" },
      { api: productSingleView, name: "productSingleViewRes" },
      { api: productSku, name: "productSkuRes" },
      { api: productCreate, name: "productCreateRes" },
      { api: productEdit, name: "productEditRes" },
      { api: productDelete, name: "productDeleteRes" },
      { api: productRemoveList, name: "productRemoveListRes" },
      { api: productRemoveSingleView, name: "productRemoveSingleViewRes" },
      { api: productFilterByPromotion, name: "productFilterByPromotionRes" },
      { api: productPricingGroup, name: "productPricingGroupRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { productDispatch } = productSlice.actions
