import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

//employeeId-get
export const employeeGetById = createAsyncThunk(
  "employee-byId",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/employeeid`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//employee-list
export const employeeList = createAsyncThunk(
  "employee-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/employees`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//employee-view
export const employeeView = createAsyncThunk(
  "//employee-view",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`employeeview/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//employee-create
export const employeeCreate = createAsyncThunk(
  "employee-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`employee`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//employee-update
export const employeeUpdate = createAsyncThunk(
  "employee-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.patch(
        `/employee/${bodyParams.id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// employee-delete
export const employeeDelete = createAsyncThunk(
  "employee-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`employee/${bodyParams.id}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
