import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// Store Create
export const createOrderTransaction = createAsyncThunk(
  'create-order-transaction',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/createtransaction`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Order Split Payment Create
export const createSplitPaymentTransaction = createAsyncThunk(
  'order-split-payment-transaction',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/createsplitpaymentorder`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Cts air pay confirm
export const ctsAirPayConfirm = createAsyncThunk(
  'cts-air-pay-confirm',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/retrivecheckout`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// Cts air pay confirm
export const orderRefundPayment = createAsyncThunk(
  'order-refund',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/refund`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// Cancel Terminal Payment 
export const cancelTerminalPayment = createAsyncThunk(
  "cancel-card-terminal",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/cancel-card-terminal`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// payment status
export const checkPaymentStatus = createAsyncThunk("check-payment-method",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/checkpaymentmethod`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })