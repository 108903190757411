import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "helpers/asyncActionHandler";
import {
  rebateCustomerList,
  rebateCustomerUpdate,
  rebateAmountReleaseHistoryList,
  rebateAmountOrdersHistoryList
} from "redux/service/menuService/customerService/rebateCustomerService";

const initialState = {
  rebateCustomerListRes: { data: [], isFetching: true, error: null },
  rebateCustomerUpdateRes: { data: [], isFetching: true, error: null },
  rebateAmountReleaseHistoryRes: { data: [], isFetching: true, error: null },
  rebateAmountOrdersHistoryRes: { data: [], isFetching: true, error: null },
};

export const rebateCustomerSlice = createSlice({
  name: "rebateCustomerSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: rebateCustomerList, name: "rebateCustomerListRes" },
      { api: rebateCustomerUpdate, name: "rebateCustomerUpdateRes" },
      { api: rebateAmountReleaseHistoryList, name: "rebateAmountReleaseHistoryRes" },
      { api: rebateAmountOrdersHistoryList, name: "rebateAmountOrdersHistoryRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { customerDispatch } = rebateCustomerSlice.actions;
