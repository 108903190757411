import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  creditMemoList,
  creditMemoCreate,
  discountMemoCreate,
} from "redux/service/menuService/customerService/discountService";

const initialState = {
  creditMemoListRes: { data: [], isFetching: false, error: null },
  creditMemoCreateRes: { data: [], isFetching: false, error: null },
  discountMemoCreateRes: { data: [], isFetching: false, error: null },
};

export const customerDiscountSlice = createSlice({
  name: "customerCreditSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: creditMemoList, name: "creditMemoListRes" },
      { api: creditMemoCreate, name: "creditMemoCreateRes" },
      { api: discountMemoCreate, name: "discountMemoCreateRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { customerDispatch } = customerDiscountSlice.actions;
