import { createSlice } from '@reduxjs/toolkit'
import {
  getSupplierCost,
  purchaseBackOrderList,
  purchaseOrderApprove, purchaseOrderBackOrderSingleView, purchaseOrderCancel, purchaseOrderCreate,
  purchaseOrderCreateProcessing,
  purchaseOrderDelete, purchaseOrderDeliveryType, purchaseOrderEdit, purchaseOrderList,
  purchaseOrderRecievedAndCancelView,
  purchaseOrderRecievedPo, purchaseOrderRecievedSingleView, purchaseOrderSingleCancel, purchaseOrderSingleView, purchaseOrderSku, supplierProductsList
} from 'redux/service/menuService/purchaseOrderService'


const initialState = {
  purchaseOrderListRes: { data: [], isFetching: true, error: null },
  supplierProductsListRes: { data: [], isFetching: true, error: null },
  purchaseOrderEditRes: { data: [], isFetching: false, error: null },
  purchaseOrderSingleViewRes: { data: [], isFetching: true, error: null },
  purchaseOrderDeleteRes: { data: [], isFetching: false, error: null },
  purchaseOrderCreateRes: { data: [], isFetching: false, error: null },
  purchaseOrderApproveRes: { data: [], isFetching: false, error: null },
  purchaseOrderCancelRes: { data: [], isFetching: false, error: null },
  purchaseOrderDeliveryTypeRes: { data: [], isFetching: false, error: null },
  purchaseOrderSkuRes: { data: [], isFetching: false, error: null },
  purchaseOrderRecievePoRes: { data: [], isFetching: false, error: null },
  purchaseOrderBackOrderSingleViewRes: { data: [], isFetching: false, error: null },
  purchaseOrderCreateProcessingRes: { data: [], isFetching: false, error: null },
  purchaseOrderSingleCancelRes: { data: [], isFetching: false, error: null },
  purchaseOrderRecievedSingleViewRes: { data: [], isFetching: false, error: null },
  getSupplierCostRes: { data: [], isFetching: false, error: null },
  purchaseOrderRecievedAndCancelViewRes: { data: [], isFetching: false, error: null },
}

export const purchaseOrderSlice = createSlice({
  name: 'purchaseOrderSlice',
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: purchaseOrderList, name: "purchaseOrderListRes" },
      { api: purchaseBackOrderList, name: "purchaseOrderListRes" },
      { api: supplierProductsList, name: "supplierProductsListRes" },
      { api: purchaseOrderSku, name: "purchaseOrderSkuRes" },
      { api: purchaseOrderDeliveryType, name: "purchaseOrderDeliveryTypeRes" },
      { api: purchaseOrderCancel, name: "purchaseOrderCancelRes" },
      { api: purchaseOrderRecievedAndCancelView, name: "purchaseOrderRecievedAndCancelViewRes" },
      { api: purchaseOrderApprove, name: "purchaseOrderApproveRes" },
      { api: purchaseOrderSingleView, name: "purchaseOrderSingleViewRes" },
      { api: purchaseOrderCreate, name: "purchaseOrderCreateRes" },
      { api: purchaseOrderEdit, name: "purchaseOrderEditRes" },
      { api: purchaseOrderDelete, name: "purchaseOrderDeleteRes" },
      { api: purchaseOrderRecievedPo, name: "purchaseOrderRecievePoRes" },
      { api: purchaseOrderBackOrderSingleView, name: "purchaseOrderBackOrderSingleViewRes" },
      { api: purchaseOrderCreateProcessing, name: "purchaseOrderCreateProcessingRes" },
      { api: purchaseOrderSingleCancel, name: "purchaseOrderSingleCancelRes" },
      { api: purchaseOrderRecievedSingleView, name: "purchaseOrderRecievedSingleViewRes" },
      { api: getSupplierCost, name: "getSupplierCostRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { purchaseOrderDispatch } = purchaseOrderSlice.actions
