import { createSlice } from '@reduxjs/toolkit'
import {
  supplierBulkUpload, supplierCreate, supplierCreateId,
  supplierDelete, supplierEdit, supplierList,
  supplierSingleVeiw
} from 'redux/service/menuService/supplierService'


const initialState = {
  supplierListRes: { data: [], isFetching: false, error: null },
  supplierDeleteRes: { data: [], isFetching: false, error: null },
  supplierCreateRes: { data: [], isFetching: false, error: null },
  supplierEditRes: { data: [], isFetching: false, error: null },
  supplierSingleVeiwRes: { data: [], isFetching: false, error: null },
  supplierBulkUploadRes: { data: [], isFetching: false, error: null },
  supplierCreateIdRes: { data: [], isFetching: false, error: null },
}

export const supplierSlice = createSlice({
  name: 'supplierSlice',
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: supplierCreateId, name: "supplierCreateIdRes" },
      { api: supplierBulkUpload, name: "supplierBulkUploadRes" },
      { api: supplierDelete, name: "supplierDeleteRes" },
      { api: supplierList, name: "supplierListRes" },
      { api: supplierSingleVeiw, name: "supplierSingleVeiwRes" },
      { api: supplierEdit, name: "supplierEditRes" },
      { api: supplierCreate, name: "supplierCreateRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { supplierDispatch } = supplierSlice.actions
