import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
const displayDateFormat = "YYYY-MM-DD";
const timeZone = dayjs.tz.guess();

export const SESSION = {
  CTS_TOKEN: "CTS_TOKEN",
  CTS_ROLE: "CTS_ROLE",
  CTS_LABEL: "label",
  CTS_EMPLOYEEID: "CTS_EMPLOYEEID",
  CTS_EMPLOYEE_NAME: "CTS_EMPLOYEE_NAME",
  CTS_REFRESH_TOKEN: "CTS_REFRESH_TOKEN",
};

export const OPTIONS = {
  statusOption: ["Active", "Inactive"],
  paymentOption: [
    { value: "cash", label: "Cash" },
    { value: "cheque", label: "Cheque" },
    { value: "email", label: "Email/Bank" },
    // { value: "bank", label: "Bank" },
    { value: "unapplied", label: "Unapplied Amt" },
    { value: "overpayment", label: "Over Payment" },
    { value: "card", label: "Credit/Debit" },
    { value: "ctsairpay", label: "Cts Air Pay" },
    { value: "split", label: "Split Payment" },
  ],
  modepaymentOption: [
    { value: "cash", label: "Cash" },
    { value: "cheque", label: "Cheque" },
    { value: "email", label: "Email/Bank" },
    { value: "card", label: "Credit/Debit" },
    { value: "ctsairpay", label: "Cts Air Pay" },
    { value: "term", label: "Term" },
    { value: "credit_memo", label: "Credit Memo" },
  ],
  inventoryOption: ["InStock", "OverStock", "LowStock"],
  discountOption: [
    { value: "0", label: "Greater Than 0%" },
    { value: "20", label: "Greater Than 20%" },
    { value: "40", label: "Greater Than 40%" },
    { value: "60", label: "Greater Than 60%" },
    { value: "80", label: "Greater Than 80%" },
  ],
  refundOption: [
    { value: "cash", label: "Cash" },
    { value: "cheque", label: "Cheque" },
    { value: "card", label: "Credit/Debit" },
    { value: "email", label: "Email/Bank" },
  ],
  salesTransactionOption: [
    { value: "return", label: "Return" },
    { value: "sales", label: "Sales" },
    { value: "cancel", label: "Cancel" },
    // { value: "backorder", label: "Backorder" },
  ],
  salesReportOption: [
    { value: "product", label: "Product" },
    { value: "order", label: "Order" },
  ],
  orderstatusOption: [
    { value: "order", label: "Order" },
    { value: "backorder", label: "Back Order" },
  ],
  paymentstatusOption: [
    { value: "paid", label: "Paid" },
    { value: "unpaid", label: "Unpaid" },
    { value: "partialy_paid", label: "Partially Paid" },
    { value: "", label: "All" },
  ],
  storeOption: ["Store", "Store & Web"],
  orderOption: [
    { value: "Pending", label: "Pending" },
    { value: "Order_Preparation", label: "Order Preparation" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Generate_packing_slip", label: "Generate packing slip" },
    { value: "Backorder", label: "Partially Completed" },
    { value: "Partially_Processed", label: "Backorder" },
    { value: "Intransit", label: "Intransit" },
    { value: "Completed", label: "Completed" },
    { value: "Waiting_To_Dispatch", label: "Waiting To Dispatch" },
  ],
  productStock: ["Product InStock", "Product OutStock"],
  productStatus: ["LIVE", "TEMP", "REMOVED"],
  genderOptions: ["Male", "Female"],
  orderTypeOptions: ["Regular Orders", "Custom Orders", "In House"],
  deliveryType: [
    { label: "Pickup", value: "pickup" },
    { label: "Delivery", value: "delivery" },
  ],
  shippingMethod: ["Store pickup", "Truck Delivery", "Direct Shipping"],
};

export const notify = (props) => {
  const errorObj = props.message;
  switch (props.success) {
    case true:
      notifications.show({
        id: "sucess",
        withCloseButton: true,
        autoClose: 3000,
        message: `${props.message}`,
        color: "green",
        className: "my-notification-class",
        loading: false,
      });
      break;
    case "warning":
      notifications.show({
        withCloseButton: true,
        autoClose: 3000,
        message: `${props.message}`,
        color: "yellow",
        className: "my-notification-class",
        loading: false,
      });
      break;
    case false:
      if (typeof errorObj === "object") {
        for (const key in errorObj) {
          notifications.show({
            withCloseButton: true,
            autoClose: 3000,
            message: errorObj[key][0],
            color: "red",
            className: "my-notification-class",
          });
        }
      } else {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          message: errorObj,
          color: "red",
          className: "my-notification-class",
        });
      }
      break;
    default:
      notifications.show({
        id: "sucess",
        withCloseButton: true,
        autoClose: 3000,
        message: "Something Went Wrong",
        color: "red",
        className: "my-notification-class",
        loading: false,
      });
      break;
  }
};

export const AddressFormater = (place) => {
  let country,
    province,
    city,
    line1,
    route,
    postal_code = null;
  if (place?.address_components !== undefined) {
    let addrComp = place.address_components;
    for (let i = 0; i < addrComp.length; ++i) {
      var typ = addrComp[i].types;
      if (compIsType(typ, "administrative_area_level_1"))
        province = addrComp[i].long_name; //store the province
      else if (
        compIsType(typ, "locality") ||
        compIsType(typ, "administrative_area_level_3")
      )
        city = addrComp[i].long_name; //store the city
      else if (compIsType(typ, "route"))
        route = addrComp[i].long_name; //store the street
      else if (compIsType(typ, "street_number"))
        line1 = addrComp[i].long_name; //store the street number
      else if (compIsType(typ, "country"))
        country = addrComp[i].long_name; //store the country
      else if (compIsType(typ, "postal_code"))
        postal_code = addrComp[i].long_name; //store the postal_code
      //we can break early if we find all three data
      if (
        province != null &&
        city != null &&
        country != null &&
        postal_code != null &&
        line1 != null &&
        route != null
      )
        break;
    }
    return {
      address: place.formatted_address,
      // country: country,
      province: province,
      city: city,
      postal_code: postal_code,
      // street_number: line1,
      street: line1 + route,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
  }
};

export const compIsType = (t, s) => {
  for (let z = 0; z < t.length; ++z) if (t[z] == s) return true;
  return false;
};

export const lumberFormula = (details) => {
  const { thick, wide, long, price, fbm, pieces, convert_type } = details;
  switch (convert_type) {
    //Feet FBM To pieces
    case 0:
      return {
        calculateEach: (((thick * wide * long) / 12) * price) / 1000,
        calculatepieces: fbm / ((thick * wide * long) / 12),
        calculateTotal:
          ((((thick * wide * long) / 12) * price) / 1000) *
          (fbm / ((thick * wide * long) / 12)),
      };
    //Inch FBM To pieces
    case 1:
      return {
        calculateEach: (((thick * wide * long) / 12 / 12) * price) / 1000,
        calculatepieces: fbm / ((thick * wide * long) / 12 / 12),
        calculateTotal:
          ((((thick * wide * long) / 12 / 12) * price) / 1000) *
          (fbm / ((thick * wide * long) / 12 / 12)),
      };
    //Feet pieces To FBM
    case 2:
      return {
        calculateEach: (((thick * wide * long) / 12) * price) / 1000,
        calculatepieces: pieces * ((thick * wide * long) / 12),
        calculateTotal:
          ((((thick * wide * long) / 12) * price) / 1000) *
          (pieces * ((thick * wide * long) / 12)),
      };
    // Inch pieces to FBM
    case 3:
      return {
        calculateEach: (((thick * wide * long) / 12 / 12) * price) / 1000,
        calculatepieces: pieces * ((thick * wide * long) / 12 / 12),
        calculateTotal:
          ((((thick * wide * long) / 12 / 12) * price) / 1000) *
          (pieces * ((thick * wide * long) / 12 / 12)),
      };
    //Feet FBM to pieces
    default:
      return {
        calculateEach: (((thick * wide * long) / 12) * price) / 1000,
        calculatepieces: fbm / ((thick * wide * long) / 12),
        calculateTotal:
          ((((thick * wide * long) / 12 / 12) * price) / 1000) *
          ((pieces * (thick * wide * long)) / 12),
      };
  }
};

export const CheckYesNo = (value) => {
  if (value === true) {
    return "yes";
  } else {
    return "no";
  }
};

export const convertDate = (date) => {
  date = dayjs(date).tz(timeZone).format(displayDateFormat);
  return date;
};

export const formateDate = (dateString) => {
  // const formattedDate = dayjs(dateString).tz(timeZone).toDate();
  // return formattedDate;
  const date = dayjs(dateString).tz(timeZone).toDate();;
  if (date instanceof Date && !isNaN(date)) {
    return date;
  }
  return null;

};
