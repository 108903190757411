import { createSlice } from '@reduxjs/toolkit'
import { cancelTerminalPayment, checkPaymentStatus, createOrderTransaction, createSplitPaymentTransaction, ctsAirPayConfirm, orderRefundPayment } from 'redux/service/paymentService/orderPaymentService';


const initialState = {
  createOrderTransactionRes: { data: [], isFetching: false, error: null },
  ctsAirPayConfirmRes: { data: [], isFetching: false, error: null },
  createSplitPaymentTransactionRes: { data: [], isFetching: false, error: null },
  orderRefundPaymentRes: { data: [], isFetching: false, error: null },
  cancelTerminalPaymentRes: { data: [], isFetching: false, error: null },
  checkPaymentStatusRes: { data: [], isFetching: false, error: null },
}

export const orderPaymentSlice = createSlice({
  name: 'orderPaymentSlice',
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: createOrderTransaction, name: "createOrderTransactionRes" },
      { api: ctsAirPayConfirm, name: "ctsAirPayConfirmRes" },
      { api: createSplitPaymentTransaction, name: "createSplitPaymentTransactionRes" },
      { api: orderRefundPayment, name: "orderRefundPaymentRes" },
      { api: cancelTerminalPayment, name: "cancelTerminalPaymentRes" },
      { api: checkPaymentStatus, name: "checkPaymentStatusRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { orderPaymentDispatch } = orderPaymentSlice.actions
