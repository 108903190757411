import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

//packingslip-list
export const packingslipList = createAsyncThunk(
  "packingslip-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/packingsliplist`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//promotionsales
export const promotionsalesList = createAsyncThunk(
  "promotionsales-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/promotionsales`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const customermasterList = createAsyncThunk(
  "customermaster-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customermaster`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const customeragingList = createAsyncThunk(
  "agingdetailsreport-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/agingdetailsreport`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const customersummaryList = createAsyncThunk(
  "receivablesummary-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/receivablesummary`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
