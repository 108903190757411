import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";



//seo-meta-list
export const seoMetaList = createAsyncThunk(
  "seo-meta-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/meta`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//seometa-view
export const seoMetaView = createAsyncThunk(
  "//seo-meta-view",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`meta/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//seometa-create
export const seoMetaCreate = createAsyncThunk(
  "seo-meta-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.postForm(`meta`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//seometa-update
export const seoMetaUpdate = createAsyncThunk(
  "seo-meta-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.postForm(
        `meta/${bodyParams.id}`,
        bodyParams.formData
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// seometa-delete
export const seoMetaDelete = createAsyncThunk(
  "seo-meta-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`meta/${bodyParams.id}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
