import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

//credits-memo
export const creditMemoList = createAsyncThunk(
  "credits-memo",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/viewcreditmemo/${bodyParams.customer}?search=${bodyParams.search}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//credit-memo-create
export const creditMemoCreate = createAsyncThunk(
  "credits-memo-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/creditmemopayment`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//discount-memo-create
export const discountMemoCreate = createAsyncThunk(
  "discount-memo-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/createcreditmemodiscounts`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
