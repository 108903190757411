import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  employeeList,
  employeeCreate,
  employeeDelete,
  employeeView,
  employeeUpdate,
  employeeGetById,
} from "redux/service/menuService/employeeService";

const initialState = {
  employeeCreateRes: { data: [], isFetching: false, error: null },
  employeeListRes: { data: [], isFetching: true, error: null },
  employeeDeleteRes: { data: [], isFetching: true, error: null },
  employeeViewRes: { data: [], isFetching: true, error: null },
  employeeUpdateRes: { data: [], isFetching: false, error: null },
  employeeGetByIdRes: { data: [], isFetching: false, error: null },
};

export const employeeSlice = createSlice({
  name: "employeeSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: employeeList, name: "employeeListRes" },
      { api: employeeCreate, name: "employeeCreateRes" },
      { api: employeeDelete, name: "employeeDeleteRes" },
      { api: employeeView, name: "employeeViewRes" },
      { api: employeeUpdate, name: "employeeUpdateRes" },
      { api: employeeGetById, name: "employeeGetByIdRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { employeeDispatch } = employeeSlice.actions;
