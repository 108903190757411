import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./slice/authSlice/authSlice";
import { storeSettingsSlice } from "./slice/settingsSlice/storeSettingsSlice";
import { supplierSlice } from "./slice/menuSlice/supplierSlice";
import { catagoriesSlice } from "./slice/menuSlice/catagoriesSlice";
import { brandSlice } from "./slice/menuSlice/brandSlice";
import { essentialSlice } from "./slice/essentialSlice/essentialSlice";
import { productSlice } from "./slice/menuSlice/productSlice";
import { purchaseOrderSlice } from "./slice/menuSlice/purchaseOrderSlice";
import { tempProductSlice } from "./slice/menuSlice/tempProductSlice";
import { customerSlice } from "./slice/menuSlice/customer/customerSlice";
import { customerDiscountSlice } from "./slice/menuSlice/customer/discountSlice";
import { customerAddressSlice } from "./slice/menuSlice/customer/customerAddressSlice";
import { customerCreditSlice } from "./slice/menuSlice/customer/creditSlice";
import { customerCardSlice } from "./slice/menuSlice/customer/cardSlice";
import { packingSlice } from "./slice/menuSlice/customer/packingSlice";
import { receivableSlice } from "./slice/menuSlice/customer/receivableSlice";
import { userSlice } from "./slice/menuSlice/customer/userSlice";
import { pricingGroupSlice } from "./slice/menuSlice/priceSlice";
import { orderSlice } from "./slice/menuSlice/orderSlice";
import { inventorySlice } from "./slice/menuSlice/inventorySlice";
//import { commonSlice } from "./slice/commonSlice/commonSlice";
import { pricingWatchSlice } from "./slice/menuSlice/priceWatchSlice";
import { priceDashBoardSlice } from "./slice/menuSlice/priceDashBoardSlice";
import { orderPaymentSlice } from "./slice/paymentSlice/orderPaymentService";
import { returnOrderSlice } from "./slice/menuSlice/returnOrderSlice";
import { commonSlice } from "./slice/commonSlice/commonSlice";
import { employeeSlice } from "./slice/menuSlice/employee";
import { packingslipSlice } from "./slice/reportSlice/packingslip";
import { inventoryReportSlice } from "./slice/reportSlice/inventoryReport";
import { billSlice } from "./slice/menuSlice/billSlice";
import { estimationQuotationSlice } from "./slice/settingsSlice/estimaQuotaSlice";
import { rebateDateSlice } from "./slice/settingsSlice/rebateDateSlice";
import { lumberCalculatorSlice } from "./slice/lumberSlice/lumberSlice";
import { financialSlice } from "./slice/reportSlice/financialReport";
import { seoMetaSlice } from "./slice/menuSlice/seometaSlice"
import { rebateCustomerSlice } from "./slice/menuSlice/customer/rebateCustomerSlice";
import { templatesSlice } from "./slice/settingsSlice/templatesSlice";
import { dashboardSlice } from "./slice/dashboardSlice/dashboardSlice";
const reducers = combineReducers({
  auth: authSlice.reducer,
  storeSettings: storeSettingsSlice.reducer,
  supplier: supplierSlice.reducer,
  catagories: catagoriesSlice.reducer,
  brand: brandSlice.reducer,
  bill: billSlice.reducer,
  essential: essentialSlice.reducer,
  product: productSlice.reducer,
  purchaseOrder: purchaseOrderSlice.reducer,
  customer: customerSlice.reducer,
  customerRebate:rebateCustomerSlice.reducer,
  customerMemo: customerDiscountSlice.reducer,
  customerAddress: customerAddressSlice.reducer,
  customerCredit: customerCreditSlice.reducer,
  customerCard: customerCardSlice.reducer,
  customerUser: userSlice.reducer,
  customerReceivable: receivableSlice.reducer,
  customerPacking: packingSlice.reducer,
  tempProduct: tempProductSlice.reducer,
  pricingGroup: pricingGroupSlice.reducer,
  order: orderSlice.reducer,
  inventory: inventorySlice.reducer,
  common: commonSlice.reducer,
  pricingWatch: pricingWatchSlice.reducer,
  priceDashBoard: priceDashBoardSlice.reducer,
  orderPayment: orderPaymentSlice.reducer,
  returnOrder: returnOrderSlice.reducer,
  employee: employeeSlice.reducer,
  seoMeta:seoMetaSlice.reducer,
  packingslip: packingslipSlice.reducer,
  inventoryReport: inventoryReportSlice.reducer,
  estimationQuotation: estimationQuotationSlice.reducer,
  rebateDate: rebateDateSlice.reducer,
  templates:templatesSlice.reducer,
  lumberCalculator: lumberCalculatorSlice.reducer,
  financial: financialSlice.reducer,
  dashboard:dashboardSlice.reducer
});

export default reducers;
