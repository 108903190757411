import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// GET DASHBOARD DETAILS
export const getDashboardDetails = createAsyncThunk(
  'get-dashboard-details',
  async (params, thunkAPI) => {
    try {
      const response = await API.get(`/dashboard-details`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })






 
  





