import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Layout from "./components/Layout";
import { Loader, LoadingOverlay } from "@mantine/core";

const CustomerRoutes = lazy(() => import("Route/customerRoute"));
const EmployeeRoutes = lazy(() => import("Route/employeeRoute"));
const OrderRoutes = lazy(() => import("Route/orderRoute"));
const PriceRoutes = lazy(() => import("Route/priceRoute"));
const ProductRoutes = lazy(() => import("Route/productRoute"));
const SeoMetaRoutes = lazy(() => import("Route/seometaRoute"));
const SettingRoutes = lazy(() => import("Route/settingRoute"));
const SupplierRoutes = lazy(() => import("Route/supplierRoute"));
const TempRoutes = lazy(() => import("Route/tempRoute"));
const InventoryRoutes = lazy(() => import("Route/inventoryRoute"));
const PurchaseRoutes = lazy(() => import("Route/purchaserderRoute"));
const CategoryRoutes = lazy(() => import("Route/categoryRoute"));
const BrandRoutes = lazy(() => import("Route/brandRoute"));
const ReportRoutes = lazy(() => import("Route/reportRoute"));
const PricingGroupPromotion = lazy(() =>
  import("pages/menu/price-management/promotion")
);
const CustomLogin = lazy(() => import("./pages/authentication/CustomLogin"));
const ForgetPassword = lazy(() =>
  import("./pages/authentication/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("./pages/authentication/ResetPassword")
);
const DashBoard = lazy(() => import("./pages/dashboard"));
const BillRoutes = lazy(() => import("Route/billRoute"));
const LumberCalculator = lazy(() => import("pages/lumber-calculator/create"));
const OrderCreatePos = lazy(() => import("pages/pos"));
const ImportRoutes = lazy(() => import("Route/importRoute"));
const Catalogue = lazy(() => import("./pages/catalogue"));
const MonerisPayment = lazy(() => import("pages/authentication/MonerisPayment"));

function App() {
  return (
    <Suspense
      fallback={
        <LoadingOverlay
          visible={true}
          loader={<Loader variant="bars" />}
          overlayBlur={1}
        />
      }
    >
      <Router>
        <Routes>
          {/* Auth Route */}
          <Route path="/login" element={<CustomLogin />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/moneris-payment" element={<MonerisPayment />} />
          {/* Acess needed */}
          <Route element={<Layout />}>
            <Route path="/" element={<DashBoard />} />
            <Route path="/menu/inventory/*" element={<InventoryRoutes />} />
            <Route path="/menu/*" element={<CustomerRoutes />} />
            <Route path="/menu/employee/*" element={<EmployeeRoutes />} />
            <Route path="/menu/order/*" element={<OrderRoutes />} />
            <Route path="/menu/pricing-group/*" element={<PriceRoutes />} />
            <Route path="/settings/*" element={<SettingRoutes />} />
            <Route path="/menu/temp/*" element={<TempRoutes />} />
            <Route path="/menu/purchase-order/*" element={<PurchaseRoutes />} />
            <Route path="/menu/supplier/*" element={<SupplierRoutes />} />
            <Route path="/menu/promotion" element={<PricingGroupPromotion />} />
            <Route path="/menu/categories/*" element={<CategoryRoutes />} />
            <Route path="/menu/brand/*" element={<BrandRoutes />} />
            <Route path="/menu/product/*" element={<ProductRoutes />} />
            <Route path="/menu/seo-meta/*" element={<SeoMetaRoutes />} />
            <Route path="/bill-management/*" element={<BillRoutes />} />
            <Route path="/lumber-calculator" element={<LumberCalculator />} />
            <Route path="/report/*" element={<ReportRoutes />} />
            <Route path="/menu/import/*" element={<ImportRoutes />} />
            <Route path="/catalogue" element={<Catalogue />} />
          </Route>
          <Route path="/pos" element={<OrderCreatePos />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
