import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "utils/api";

// estimation quotation
export const updateEstimatioQuotation = createAsyncThunk(
  'estimation-quotation-settings',
  async ({ value, bodyParams }, thunkAPI) => {
    try {
      const response = await API.put(`/estimationquotation/${value}`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
