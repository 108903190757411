import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../helpers/asyncActionHandler";
import {
  seoMetaList,
  seoMetaCreate,
  seoMetaDelete,
  seoMetaView,
  seoMetaUpdate,
} from "redux/service/menuService/seoMetaService";

const initialState = {
  seoMetaCreateRes: { data: [], isFetching: false, error: null },
  seoMetaListRes: { data: [], isFetching: true, error: null },
  seoMetaDeleteRes: { data: [], isFetching: true, error: null },
  seoMetaViewRes: { data: [], isFetching: true, error: null },
  seoMetaUpdateRes: { data: [], isFetching: false, error: null },
};

export const seoMetaSlice = createSlice({
  name: "seoMetaSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: seoMetaList, name: "seoMetaListRes" },
      { api: seoMetaCreate, name: "seoMetaCreateRes" },
      { api: seoMetaDelete, name: "seoMetaDeleteRes" },
      { api: seoMetaView, name: "seoMetaViewRes" },
      { api: seoMetaUpdate, name: "seoMetaUpdateRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { seoMetaDispatch } = seoMetaSlice.actions;
